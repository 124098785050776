import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import { getPrettyURL } from "../utils"
import { SeoDataModel } from "../models/common"
import { getLocaleConfig } from "../utils/utils_locales"
import { LOCALE_DIRS, LOCALE_LOOKUPS} from "../utils/constants";
import { getHeroHeading } from "../utils/utils_text"

interface SeoProps extends SeoDataModel {}

const defaultProps = {
  description: ``,
  lang: `en`,
  meta: [],
}

export const Seo: React.FC<SeoProps> = props => {
  // graphQL
  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
          author
        }
      }
      seoTitle: allContentstackConfig(filter: { code_id: { eq: "seoTitle" } }) {
        nodes {
          ...ConfigFields
        }
      }
      seoTitlePipe: allContentstackConfig(
        filter: { code_id: { eq: "seoTitlePipe" } }
      ) {
        nodes {
          ...ConfigFields
        }
      }
      seoDescription: allContentstackConfig(
        filter: { code_id: { eq: "seoDescription" } }
      ) {
        nodes {
          ...ConfigFields
        }
      }
      shareImage: allContentstackConfig(
        filter: { code_id: { eq: "shareImage" } }
      ) {
        nodes {
          ...ConfigFields
        }
      }
    }
  `)

  // gatsby-config values
  const siteTitle: string = query.site.siteMetadata?.title
  const siteDescription: string = query.site.siteMetadata?.description
  const siteUrl: string = query.site.siteMetadata?.siteUrl
  const siteAuthor: string = query.site.siteMetadata?.author

  // CMS config values
  const seoTitle = getLocaleConfig(query.seoTitle, props.locale)?.text
  const seoTitlePipe = getLocaleConfig(query.seoTitlePipe, props.locale)?.text
  const seoDescription = getLocaleConfig(
    query.seoDescription,
    props.locale
  )?.text
  const seoShareImage = getLocaleConfig(
    query.seoShareImage,
    props.locale
  )?.image

  // fallbacks
  const title: string = props.metaTitle || seoTitle || siteTitle || ""
  const shareTitle: string = getHeroHeading(props.heading) || title
  const description: string =
    props.metaDescription || seoDescription || siteDescription || ""
  const canonicalLink: string =
    props.slug === "/"
      ? siteUrl
      : getPrettyURL(siteUrl + "/" + props.slug);

  const isPageSlugExist = !!props.slug;

  const shareImage =
    props.metaImage || (seoShareImage ? seoShareImage[0] : undefined)
    
  return (
    <Helmet
      htmlAttributes={{ lang: props.lang }}
      title={title}
      titleTemplate={`%s | ${seoTitlePipe || siteTitle}`}
    >
      {/* open graph */}
      <meta name="title" property="og:title" content={shareTitle} />
      <meta
        name="description"
        property="og:description"
        content={description}
      />
      <meta property="og:type" content={props.type || "website"} />
      {isPageSlugExist && <meta property="og:url" content={canonicalLink} />}

      {/* twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={siteAuthor || ""} />
      <meta name="twitter:title" content={shareTitle} />
      <meta name="twitter:description" content={description} />

      {/* share image */}
      <meta name="twitter:image" content={shareImage?.url} />
      <meta property="og:image" name="image" content={shareImage?.url} />
      <meta property="og:image:type" content={shareImage?.content_type} />
      <meta
        property="og:image:alt"
        content={shareImage?.description || shareImage?.title || ""}
      />
      <meta
        property="og:image:width"
        content={shareImage?.dimension?.width?.toString()}
      />
      <meta
        property="og:image:height"
        content={shareImage?.dimension?.height?.toString()}
      />

      {/* Links */}
      {isPageSlugExist && <link rel="canonical" href={canonicalLink} />}

      {LOCALE_DIRS.map((item) => {
        const langLink =
          props.pageContext &&
          props.pageContext.langs &&
          props.pageContext.langs.find((x: any) => x.locale === item)

        return (
          langLink && (
            <link
              key={langLink.url}
              rel="alternate"
              href={`https://www.sanitarium.com${
                langLink.url.startsWith("/") ? langLink.url : `/${langLink.url}`
              }`}
              hrefLang={(LOCALE_LOOKUPS as any)[item].locale}
            />
          )
        )
      })}

      {props.pageContext &&
        props.pageContext.langs &&
        props.pageContext.langs.find((x: any) => x.locale === "au") && (
          <link
            rel="alternate"
            href={`https://www.sanitarium.com${
              props.pageContext.langs
                .find((x: any) => x.locale === "au")
                .url.startsWith("/")
                ? props.pageContext.langs.find((x: any) => x.locale === "au")
                    .url
                : `/${
                    props.pageContext.langs.find((x: any) => x.locale === "au")
                      .url
                  }`
            }`}
            hrefLang="x-default"
          />
        )}
      {props.pageContext &&
        props.pageContext.langs &&
        !props.pageContext.langs.find((x: any) => x.locale === "au") &&
        props.pageContext.langs.find((x: any) => x.locale === "nz") && (
          <link
            rel="alternate"
            href={`https://www.sanitarium.com${
              props.pageContext.langs
                .find((x: any) => x.locale === "nz")
                .url.startsWith("/")
                ? props.pageContext.langs.find((x: any) => x.locale === "nz")
                    .url
                : `/${
                    props.pageContext.langs.find((x: any) => x.locale === "nz")
                      .url
                  }`
            }`}
            hrefLang="x-default"
          />
        )}
      {/* Keywords */}
      {props.metaKeywords && (
        <meta name="keywords" content={props.metaKeywords} />
      )}

      {props.amp && (
        <link
          rel="amphtml"
          href={canonicalLink
            .replace("/au/", "/amp/au/")
            .replace("/nz/", "/amp/nz/")}
        ></link>
      )}
    </Helmet>
  )
}

Seo.defaultProps = defaultProps

export default Seo
